import 'isomorphic-fetch';
import siteSettings from '../config/settings';

let chaosEnabled = siteSettings.chaos || false;
let endpoint = siteSettings.endpoints.api || false;

// Server-side we stay within the docker network
// ...And NO CHAOS!
if (typeof window === 'undefined') {
  chaosEnabled = false;
  endpoint = `http://${process.env.API_SERVICE_HOST}:${process.env.API_SERVICE_PORT}/`;
}

// "Chaos" means we also fake a delay to simulate network latency
const fakeNetworkDelayMin = 400; // ms
const fakeNetworkDelayMax = 1500; // ms

// Add a chance any given API call will fail (if chaosEnabled is set to true)
const errorRate = .05;

const defaultPaginationSize = 8;

const chaoticPromiseGenerator = resolutionValue => new Promise( (resolve, reject) => {
  if (chaosEnabled) {
    const delay = Math.floor(fakeNetworkDelayMin + (Math.random() * (fakeNetworkDelayMax - fakeNetworkDelayMin)));
    const success = Math.random() > errorRate;
    setTimeout(function() {
      success ? resolve(resolutionValue) : reject('Fake, chaos-based, API error');
    }, delay);
  } else {
    resolve(resolutionValue);
  }
});

const normalizeQueryData = (queryObject) => {
  const normalizedQueryData = [];
  Object.keys(queryObject).map( (key) => {
    if (typeof queryObject[key] !== 'object') {
      normalizedQueryData.push(`${encodeURIComponent(key)}=${encodeURIComponent(queryObject[key])}`);
    } else {
      Object.keys(queryObject[key]).map(
        innerKey =>
          normalizedQueryData.push(
            `${encodeURIComponent(key)}[${innerKey}][]=${encodeURIComponent(queryObject[key][innerKey])}`,
          ),
      );
    }
    return true;
  });
  return normalizedQueryData;
};

async function getConfig (type) {
  if (type) {
    console.warn(`Requested specific config of type ${type}, but we don't have that functionality yet. You're getting the whole lot.`);
  }
  const url = endpoint + 'config';
  const options = { method: 'GET' };

  return fetch(url, options)
    .then(response => response.json())
    .then(json => json.data ? json.data[0] : json);
}

export const getPanelsByType = (type = '', {productId = 0, isDonation = 0}) => {

  const url = `${endpoint}fetch/panels/${type}?productId=${productId}&isDonation=${isDonation}`;
  const options = { method: 'GET' };

  return fetch(url, options)
    .then(response => response.json())
    .then(json => json.data ? json.data[0] : json);

};

async function makePaymentProviderRequest (method, provider, task, data) {
  const url = `${endpoint}payment/${provider}/${task}`;
  const options = {
    method,
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (data) {
    options.body = JSON.stringify(data);
  }

  return fetch(url, options)
    .then((response) => {
      return response.json();
    });
}

async function submitData (type, data) {
  const url = `${endpoint}submit/${type}`;
  const options = {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (data) {
    options.body = JSON.stringify(data);
  }
  return fetch(url, options)
    .then((response) => {
      return response.json();
    });
}

async function getById (apiService = '', id = '') {
  const url = endpoint + apiService + '/' +  encodeURIComponent(id);
  const options = { method: 'GET' };

  return fetch(url, options)
    .then(response => response.json())
    .then(json => json.data ? json.data[0] : json);
}

async function getByQuery (apiService = '', queryData = {}) {
  const escapedQueryString = normalizeQueryData(queryData).join('&');
  const url = endpoint + apiService + (escapedQueryString ? '?' + escapedQueryString : '');
  const options = {
    method: 'GET',
  };
  return fetch(url, options)
    .then(response => response.json())
    .then(json => json.data);
}

/*
* Feathers doc tip: With pagination enabled, to just get the number of available records set $limit to 0. This will only run a (fast) counting query against the database and return a page object with the total and an empty data array.
* https://docs.feathersjs.com/api/databases/querying.html
*/
async function getQueryCount (apiService = '', queryData = {}) {
  const escapedQueryString = normalizeQueryData(queryData).join('&');
  const url = endpoint + apiService + (escapedQueryString ? '?' + escapedQueryString + '&$limit=0' : '');
  const options = {
    method: 'GET',
  };
  return fetch(url, options)
    .then(response => response.json())
    .then(json => json.total);
}

async function getBySlug (apiService = '', slug = '') {
  const query = { slug };
  return getByQuery(apiService, query)
    .then(response => response[0]);
}

async function getByHash (apiService = '', hash = '') {
  const query = { hash };
  return getByQuery(apiService, query)
    .then(response => response[0]);
}

export const getSiteConfig = () =>
  chaoticPromiseGenerator(getConfig());

export const getPaymentProviders = () =>
  chaoticPromiseGenerator(makePaymentProviderRequest('GET', 'info', 'providers'));

export const getPaymentProviderToken = provider =>
  chaoticPromiseGenerator(makePaymentProviderRequest('GET', provider, 'token'));

export const submitOrder = (order, transaction) =>
  chaoticPromiseGenerator(submitData('order', { order, transaction }));

export const getCategoryBySlug = categorySlug =>
  chaoticPromiseGenerator(getBySlug('categories', categorySlug));

export const getCategoryById = categoryId =>
  chaoticPromiseGenerator(getById('categories', categoryId ));

export const getProductBySlug = productSlug =>
  chaoticPromiseGenerator(getBySlug('products', productSlug));

export const getProductById = productId =>
  chaoticPromiseGenerator(getById('products', productId ));

export const getPageBySlug = pageSlug =>
  chaoticPromiseGenerator(getBySlug('pages', pageSlug));

export const getPageById = pageId =>
  chaoticPromiseGenerator(getById('pages', pageId ));

export const getProductsByCategorySlug = (categorySlug, limit = defaultPaginationSize, skip = 0) => {
  return chaoticPromiseGenerator(
    getCategoryBySlug(categorySlug)
      .then(response => response.id || response._id)
      .then(id => getProductsByCategoryId(id, limit, skip)),
  );
};

export const getTotalProductsByCategorySlug = (categorySlug) => {
  return chaoticPromiseGenerator(
    getCategoryBySlug(categorySlug)
      .then(response => response.id || response._id)
      .then(id => getTotalProductsByCategoryId(id)),
  );
};

export const getProductsByCategoryId = (categoryId, limit = defaultPaginationSize, skip = 0, visible = true )  =>
  chaoticPromiseGenerator(getByQuery('products', {
    categoryIds: { $in: categoryId },
    visible: visible,
    $skip: skip,
    $limit: limit,
    $sort: { createdAt: -1 },
  }));

export const getTotalProductsByCategoryId = (categoryId, visible = true)  =>
  chaoticPromiseGenerator(getQueryCount('products', { categoryIds: { $in: categoryId }, visible: visible }));

export const getOrderByHash = orderHash =>
  chaoticPromiseGenerator(getByHash('orders', orderHash));

export const getGiftByHash = giftHash =>
  chaoticPromiseGenerator(getByHash('gifts', giftHash));

export const getUserByHash = userHash =>
  chaoticPromiseGenerator(getByHash('users', userHash));

// Default export, in case we want the whole lot
export default {
  getSiteConfig,
  getPaymentProviders,
  getPaymentProviderToken,
  submitOrder,
  getCategoryBySlug,
  getCategoryById,
  getPageBySlug,
  getPageById,
  getProductBySlug,
  getProductById,
  getProductsByCategorySlug,
  getTotalProductsByCategorySlug,
  getProductsByCategoryId,
  getTotalProductsByCategoryId,
  getUserByHash,
  getOrderByHash,
  getPanelsByType,
  getGiftByHash,
};
