import React from 'react';
import { Row, Col } from 'react-bootstrap';
import propTypes from 'prop-types';

import Product from './Product';

import { displayInputAsCurrency } from '../utilities/currency';
import { formatDateString } from '../utilities/date';

class Gift extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      copied: false,
    };
  }

  static get propTypes() {
    return {
      gift: propTypes.object,
    };
  }

  copyGiftUrlToClipboard = (e) => {
    const textarea = document.createElement('textarea');
    textarea.value = e.target.dataset.giftUrl;
    e.target.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    e.target.removeChild(textarea);
    e.target.focus();
    this.setState({ copied: true });
    window.setTimeout( () => {
      this.setState({ copied: false });
    }, 1500);
  };

  render () {
    const giftUrl = `${process.env.REACT_APP_BASE_URL}gift/${this.props.gift.hash}`;
    const giftTitle = this.props.gift.archived === true ?
      'Expired Gift' :
      `Gift for ${this.props.gift.recipient.name}`;
    const giftDesc =  this.props.gift.archived === true ?
      'This gift was sent more than 12 months ago. We’ve deleted the details and the recipient’s personal information for your safety.' :
      (this.props.gift.recipient.address || 'Address not found');

      return (
        <div className="Gift">
        <p className="title">{giftTitle}</p>
        <p>{giftDesc}</p>
        <Row className="align-items-start mb-4">
          <Col xs={12} sm={4} lg={12} xl={4}>
            <Product mode="thumbnail" thumbnailLink={false} productId={this.props.gift.productId} />
          </Col>
          <Col xs={12} sm={8} lg={12} xl={8} className="details mt-4 mt-sm-0 mt-lg-4 mt-xl-0">
            { !!this.props.gift.value &&
              <p>
                <strong>Donation: </strong>
                { displayInputAsCurrency(this.props.gift.valueForDisplay || this.props.gift.value, true, true, true, true) }
                { !!this.props.gift.valueForDisplay &&
                  <span> (shared across multiple gifts)</span>
                }
              </p>
            }
            <p><strong>Scheduled:</strong> {formatDateString(this.props.gift.scheduledSendTime)}</p>
            { this.props.gift.actualSendTime &&
              <p><strong>Sent:</strong> {formatDateString(this.props.gift.actualSendTime)}</p>
            }
            <p><strong>Status:</strong> {this.props.gift.status[0].toUpperCase() + this.props.gift.status.slice(1)}</p>
            <p><a className="no-link-decoration" href={`${giftUrl}?from=user-page`} target="_blank" rel="noopener noreferrer">&raquo; Preview this gift</a></p>
          </Col>
        </Row>

        {/* Raw link and copy-to-clipboard button */}
        <p><strong>Unique link to send in a direct message:</strong><br />{giftUrl}</p>
        {
          typeof window !== 'undefined' && document.queryCommandSupported('copy') &&
          <button data-gift-url={giftUrl} className="btn btn-primary text-uppercase" onClick={this.copyGiftUrlToClipboard} disabled={this.state.copied}>{this.state.copied ? 'Link copied!' : 'Copy this link'}</button>
        }
        
        </div>
      );
  }

}

export default Gift;
